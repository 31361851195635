export const SupportsMany = [
    {
        name: "Wholesale and retail"
    },
    {
        name: "Service seller or product sellers"
    },
    {
        name: "One shutter shop to department stores"
    },
    {
        name: "Grocery to Medical"
    },
];

export const IntegrationWithCoop = [
    {
        name: "Nepali Date",
        desc: "OXPAN CoopMart follows the nepali calendars and fiscal years"
    },
];

export const FollowsRules = [
    {
        name: "Double Entry Accounting System",
        desc: "OXPAN Mart is useful making double entry system"
    },
    {
        name: "Nepali Date",
        desc: "OXPAN Mart follows the nepali calendars and fiscal years"
    },
    {
        name: "Tax Provisions",
        desc: "Income-tax, Tax Deduction in Source(TDS) are calculated automatically"
    },
    {
        name: "Depreciations",
        desc: "Depreciation: Depreciation is deducted as per the rules"
    },
];

export const ModernFac = [
    {
        name: "Barcode QR code Reading"
    },
    {
        name: "SMS Facility"
    },
    {
        name: "Debtors Aging"
    },
    {
        name: "Inter branch transaction"
    },
];

export const EasyAndAttMart = [
    {
        desc: "OXPAN Mart is the software which teaches you what a accounting system is, what we a software is. You learn entire accounting by using this software. This is the software from which you can learn and enjoy working."
    }
];

export const PlentyOfReportsMart = [
    {
        name: "Group Based Membership Detail and Account Details"
    },
    {
        name: "Complete double entry system ledger with sub ledger"
    },
    {
        name: "Branch-wise and group-wise Central report regarding"
    },
    {
        name: "Regular and overdue debts reports"
    },
    {
        name: "Overall transaction report"
    },
    {
        name: "Branch-wise and central report of Trial balance"
    },
    {
        name: "Balance sheet and income statement and cash flow statement"
    },
    {
        name: "Financial Reports"
    },
    {
        name: "Daybooks and Transaction Sheets"
    },
    {
        name: "Progress Reports"
    },
    {
        name: "Comparative Periodic and subject-wise report"
    },
    {
        name: "Financial Analytical Tools"
    },
    {
        name: "MIS Reports"
    },
];

export const FinancialAnalyticalToolsMart = [
    {
        name: "Ratio anlysis"
    },
    {
        name: "Profitability Analysis"
    },
    {
        name: "Periodic Progress reports"
    },
];

export const SelfDesigningMart = [
    {
        desc: "OXPAN Mart provides facility to design and plan new models in the cooperative by applying various options given for changing various rates and plans."
    }
];

import React from 'react'
import "../css/testimonials.css"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'

import "swiper/swiper.min.css";
import "swiper/css/pagination";


const Testimonials = () => {

    const testimonialList = [
        {
            testimonialsID: "1",
            message: "We have been using OXPAN since 2065, and it is the best cooperative software in Nepal. The best part of this organization is that Oxpan provides its services at any time, which is very convenient for us.",
            coopName: "Karobar saving and credit cooperative limited",
        },
        {
            testimonialsID: "2",
            message: "The OXPAN Cooperator Software is simple and easy to use. Our cooperative teams are satisfied and happy with your software and services. We wish you a successful career for your company.",
            coopName: "HasiKhusi Sahakari limited",
        },
        {
            testimonialsID: "3",
            message: "Oxpan provides excellent services even outside of the valley. They offer both online and offline services, which makes our work easier. We wish you all the best for your future endeavors.",
            coopName: "City Cooperative"
        }
    ]

    return (

        <>
            <div className="testimonials">
                <h2><span>TESTIMONIALS</span></h2><hr />
            </div>
            <div className="testimonials-body">
                <h3>What Our Client Says About Us ?</h3>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    loop={true}
                    grabCursor={true}
                    pagination={{ clickable: true, dynamicBullets: true }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}
                    modules={[Pagination, Autoplay]}
                    className="slide-container-testimonials">
                    <div className="slide-content-testimonials">
                        <div className="card-wrapper-testimonials">
                            {testimonialList.map((item, id) => {
                                return (
                                    <SwiperSlide className="card shadow">
                                        <div className="desc-content-testimonials">
                                            <div className="card-desc-testimonials">
                                                <p className='desc-testimonials'>" {item.message} "</p>
                                            </div>
                                        </div>
                                        <div className="card-content-testimonials">
                                            <h6 className='name-testimonials'>&mdash; {item.coopName}</h6>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </div>
                    </div>
                </Swiper>
            </div>
        </>
    )
}

export default Testimonials
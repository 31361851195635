import React from 'react'
import '../css/productslider.css'
import Bancos from '../images/products/bancos-in-laptop.png'
import Coponext from '../images/products/coponext-in-monitor.jpg'
import Goldplus from '../images/products/goldplus-in-laptop.jpg'
import Dugdha from "../images/products/dugdha-in-monitor.jpg"
import AllMart from "../images/products/allmart-in-laptop.jpg"
import CoopMart from "../images/products/coopmart-in-laptop.jpg"
import Micos from "../images/products/micos-in-laptop.jpg"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Autoplay } from 'swiper'
// import CustomNavigation from './CustomNavigation'

import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";


const ProductSlider = () => {

    const productList = [
        {
            productID: "1",
            productName: "BANCOS",
            productImg: Bancos,
            productAlt: "Bancos",
            path: "/bancos"
        },
        {
            productID: "2",
            productName: "COPONEXT",
            productImg: Coponext,
            productAlt: "Coponext",
            path: "/coponext"
        },
        {
            productID: "3",
            productName: "Cooperator Gold plus",
            productImg: Goldplus,
            productAlt: "Goldplus",
            path: "/goldplus"
        },
        {
            productID: "4",
            productName: "CoopMart",
            productImg: CoopMart,
            productAlt: "Coopmart",
            path: "/coopmart"
        },
        {
            productID: "5",
            productName: "MiCOS",
            productImg: Micos,
            productAlt: "Micos",
            path: "/micos"
        },
        {
            productID: "6",
            productName: "Cooperator Dugdha",
            productImg: Dugdha,
            productAlt: "Coopdugdha",
            path: "/dugdha"
        },
        {
            productID: "7",
            productName: "AllMart",
            productImg: AllMart,
            productAlt: "Allmart",
            path: "/allmart"
        },
    ]

    return (

        <>
            <div className="products">
                <h2><span>OUR PRODUCTS</span></h2><hr />
            </div>
            <div className="product-body">
                <h3>We Offer Our Best Co-operative Softwares</h3>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={20}
                    // slidesPerGroup={1}
                    loop={true}
                    // centeredSlides={true}
                    // fadeEffect={true}
                    // effect="card"
                    grabCursor={true}
                    pagination={{ clickable: true, dynamicBullets: true }}
                    navigation={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1
                        },
                        670: {
                            slidesPerView: 2
                        },
                        950: {
                            slidesPerView: 3
                        },
                        1250: {
                            slidesPerView: 4
                        },
                    }}
                    modules={[Pagination, Navigation, Autoplay]}
                    className="slide-container-product">
                    <div className="slide-content-product">
                        <div className="card-wrapper-product">
                            {productList.map((item, id) => {
                                return (
                                    <SwiperSlide className="card shadow">
                                        <div className="card-content-product">
                                            <h4 className="name-product" >{item.productName}</h4>
                                        </div>
                                        <div className="image-content-product">
                                            <div className="card-image-product">
                                                <img src={item.productImg} alt={item.productAlt} className="card-img-product" />
                                            </div>
                                        </div>
                                        <div className="btn-content-product">
                                            <button className="button-product">
                                                <a href={item.path}>View Detail</a>
                                            </button>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </div>
                    </div>
                </Swiper>
            </div>
        </>

    )
}

export default ProductSlider
import React, { useState, useEffect } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import Header from './Header'
import Logo from '../../images/OmsLogo2.png'
import { Link, useLocation } from 'react-router-dom'
import {
    productsDropdown,
    servicesDropdown,
    downloadDropdown,
    companyDropdown
} from './NavItems'
import "../../css/topbar.css"

const TopBar = () => {

    const location = useLocation()

    const [activePage, setActivePage] = useState(location.pathname)

    const [sticky, setSticky] = useState(false)

    const resetScroll = () => {
        window.scrollTo({
            top: 0
        });
    }

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 190)
            // console.log(window.scrollY)
        }
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    })

    return (

        <>

            <div className="">
                <Header />
            </div>

            <div className={`${sticky ? "sticky" : ""}`}>

                <Navbar collapseOnSelect expand="lg" className='navigationbar'>
                    <Navbar.Brand as={Link} to="/" className='navbar-brand'>
                        <img src={Logo} alt="logo" className='navbar-logo' />
                    </Navbar.Brand>

                    <Container className='containernav'>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='iconnav'/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="justify-content-end flex-grow-1 pe-5">

                                <Nav.Link as={Link} to="/" className="link">

                                    <span className={`${activePage === "/" ? "active" : ""}`} onClick={() => { setActivePage("/"); resetScroll(); }}>
                                        HOME
                                    </span>

                                </Nav.Link>

                                <NavDropdown
                                    title={<span className="link">PRODUCT</span>}
                                    id="collasible-nav-dropdown"
                                    className='link'

                                >

                                    {productsDropdown.map((item1) => {
                                        return (
                                            <NavDropdown.Item as={Link} to={item1.path} className={item1.cName}>
                                                <span className={`${activePage === item1.path ? "active" : ""}`} onClick={() => { setActivePage(item1.path); resetScroll(); }}>{item1.title}</span>
                                            </NavDropdown.Item>
                                        )
                                    })}

                                </NavDropdown>

                                <NavDropdown title={<span className="link">SERVICES</span>} id='collasible-nav-dropdown' className='link'>

                                    {servicesDropdown.map((item2) => {
                                        return (
                                            <NavDropdown.Item as={Link} to={item2.path} className={item2.cName}>
                                                <span className={`${activePage === item2.path ? "active" : ""}`} onClick={() => { setActivePage(item2.path); resetScroll(); }}>{item2.title}</span>
                                            </NavDropdown.Item>
                                        )
                                    })}

                                </NavDropdown>

                                <NavDropdown title={<span className="link">DOWNLOAD</span>} id="collasible-nav-dropdown" className='link'>

                                    {downloadDropdown.map((item3) => {
                                        return (
                                            <NavDropdown.Item as={Link} to={item3.path} className={item3.cName}>
                                                <span className={`${activePage === item3.path ? "active" : ""}`} onClick={() => { setActivePage(item3.path); resetScroll(); }}>{item3.title}</span>
                                            </NavDropdown.Item>
                                        )
                                    })}

                                </NavDropdown>

                                <NavDropdown title={<span className="link">COMPANY</span>} id="collasible-nav-dropdown" className='link'>

                                    {companyDropdown.map((item4) => {
                                        return (
                                            <NavDropdown.Item as={Link} to={item4.path} className={item4.cName}>
                                                <span className={`${activePage === item4.path ? "active" : ""}`} onClick={() => { setActivePage(item4.path); resetScroll(); }}>{item4.title}</span>
                                            </NavDropdown.Item>
                                        )
                                    })}

                                </NavDropdown>

                                <Nav.Link as={Link} to="/contactus" className="link"><span className={`${activePage === "/contact" ? "active" : ""}`} onClick={() => { setActivePage("/contact"); resetScroll(); }}>CONTACT US</span></Nav.Link>

                            </Nav>
                        </Navbar.Collapse>
                    </Container>

                </Navbar>

            </div>
        </>

    )
}

export default TopBar
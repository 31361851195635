import React, { useState } from 'react'
import {
    Carousel,
    Modal,
    Form,
    Col,
    Row,
    Button
} from 'react-bootstrap'
import Slide2 from '../images/slider-image/slide1.jpg'
import Slide1 from '../images/slider-image/slide2.jpg'
import Slide3 from '../images/slider-image/slide3.jpg'
import Slide4 from '../images/slider-image/slide4.jpg'
import Slide5 from '../images/slider-image/slide5.jpg'
import { Formik } from 'formik'
import * as Yup from 'yup'
import swal from 'sweetalert'
import ContactIcon from "../images/contact-icon.png"
import emailjs from "@emailjs/browser"

import '../css/imageslider.css'

const ImageSlider = () => {

    const [index, setIndex] = useState(0)
    const [showContact, setShowContact] = useState(false)

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex)
    }

    const handleShowContact = () => setShowContact(true)
    const handleCloseContact = () => setShowContact(false)

    const { REACT_APP_PUBLIC_KEY, REACT_APP_TEMPLATE_ID, REACT_APP_SERVICE_ID } = process.env

    const sliderImg = [
        {
            slideName: "OXPAN CopoNext",
            slideDesc: "New Web Version of OXPAN Software",
            slidePhoto: Slide1,
            slideAltName: "coponext"
        },
        {
            slideName: "OXPAN Cloud Service",
            slideDesc: "Most Secure and Fast Cloud Service",
            slidePhoto: Slide2,
            slideAltName: "cloud service"
        },
        {
            slideName: "OXPAN Bancos",
            slideDesc: "New and Upgraded Version of",
            slideDesc2: "OXPAN Cooperator Gold Plus",
            slidePhoto: Slide3,
            slideAltName: "bancos"
        },
        {
            slideName: "OXPAN Collection App",
            slideDesc: "Cooperative Market Collection App (For Android)",
            slidePhoto: Slide4,
            slideAltName: "collection app"
        },
        {
            slideName: "",
            slideDesc: "",
            slidePhoto: Slide5,
            slideAltName: "Slider5"
        }
    ]

    const phoneRegExp = /^\d{10}$/; // Regular expression to match 10-digit phone number

    const validateContactSchema = Yup.object().shape({
        name: Yup.string()
            .required("* Name is Required !!")
            .min(5, "* Must have at least 5 Characters !!")
            .max(25, "* Must be less than 25 Characters !!"),
        coopname: Yup.string()
            .required("* Cooperative Name is Required !!")
            .min(5, "* Must have at least 5 Characters !!")
            .max(60, "* Must be less than 60 Characters !!"),
        contactno: Yup.string()
            .matches(phoneRegExp, "* Invalid Number !!")
            .required("* Number Required !!"),
        address: Yup.string()
            .required("* Address is Required !!")
            .min(5, "* Must have at least 5 Characters !!")
            .max(30, "* Must be less than 30 Characters !!"),
        message: Yup.string()
            .required("* Message is Required !!")
            .min(5, "* Must have at least 5 Characters !!")
            .max(100, "* Must be less than 100 Characters !!"),
        email: Yup.string().email()
            .required("* Email is Required!!"),
        subject: Yup.string()
            .required("* Subject is Required !!")
            .min(5, "* Must have at least 5 Characters !!")
            .max(50, "* Must be less than 50 Characters !!"),
    })

    return (

        <>
            <div className="container">

                <div className="slider-section">

                    <Carousel fade activeIndex={index} onSelect={handleSelect} interval={4000} controls={false}>

                        {sliderImg.map((item, idx) => {
                            return (
                                <Carousel.Item>
                                    <img className="d-block w-80" src={item.slidePhoto} alt={item.slideAltName} />
                                    <div className="caption-content-slider">
                                        <h3 className="slider-h3">{item.slideName}</h3>
                                        <p className="slider-p">{item.slideDesc}</p>
                                        <p className="slider-p1">{item.slideDesc2}</p>
                                    </div>
                                    <div className="btn-content-slider">
                                        <button className="btn-sliderimg" onClick={handleShowContact}>
                                            <a href="/#">Contact Us</a>
                                        </button>
                                    </div>
                                </Carousel.Item>
                            )
                        })}

                    </Carousel>

                </div>

            </div>

            <Modal show={showContact} onHide={handleCloseContact} className="modal">

                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title>
                        <img src={ContactIcon} alt="contact" className="contact-icon" />
                        <span className="contact-span">Contact Us</span>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Formik
                        initialValues={{ name: "", coopname: "", contactno: "", address: "", email: "", subject: "", message: "" }}
                        validationSchema={validateContactSchema}
                        onSubmit={
                            (values, { setSubmitting, resetForm }) => {
                                // console.log(values)
                                setSubmitting(true)
                                emailjs.send(`${REACT_APP_SERVICE_ID}`, `${REACT_APP_TEMPLATE_ID}`, values, `${REACT_APP_PUBLIC_KEY}`)
                                    .then(resetForm())
                                    .then(setSubmitting(false))
                                    .then(swal({
                                        title: "Detail Send !",
                                        text: "Thank You, We'll Contact You Shortly !",
                                        icon: "success",
                                    }))
                            }
                        }
                    >

                        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (

                            <Form onSubmit={handleSubmit}>

                                <Form.Group as={Row} className="mb-3" controlId="name">

                                    <Form.Label column sm="2">Name :</Form.Label>

                                    <Col sm="8">
                                        <Form.Control type="text" name="name" placeholder="Enter Your Name" onChange={handleChange} value={values.name} style={{ borderColor: touched.name && errors.name ? "red" : null }} />
                                        {touched.name && errors.name ? (
                                            <Col className="error-message">{errors.name}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="coopname">

                                    <Form.Label column sm="3">Cooperative Name:</Form.Label>

                                    <Col sm="8">
                                        <Form.Control type="text" name="coopname" placeholder="Enter Cooperative Name" onChange={handleChange} value={values.coopname} style={{ borderColor: touched.coopname && errors.coopname ? "red" : null }} />
                                        {touched.coopname && errors.coopname ? (
                                            <Col className="error-message">{errors.coopname}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="contactno">

                                    <Form.Label column sm="3">Mobile NO:</Form.Label>

                                    <Col sm="8">
                                        <Form.Control type="text" name="contactno" placeholder="Enter Mobile Number" onChange={handleChange} value={values.contactno} style={{ borderColor: touched.contactno && errors.contactno ? "red" : null }} />
                                        {touched.contactno && errors.contactno ? (
                                            <Col className="error-message">{errors.contactno}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="address">

                                    <Form.Label column sm="3">Address:</Form.Label>

                                    <Col sm="8">
                                        <Form.Control type="text" name="address" placeholder="Enter Address" onChange={handleChange} value={values.address} style={{ borderColor: touched.address && errors.address ? "red" : null }} />
                                        {touched.address && errors.address ? (
                                            <Col className="error-message">{errors.address}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="email">

                                    <Form.Label column sm="3">Email :</Form.Label>

                                    <Col sm="8">
                                        <Form.Control type="email" name="email" placeholder="Enter Your Email" onChange={handleChange} value={values.email} style={{ borderColor: touched.email && errors.email ? "red" : null }} />
                                        {touched.email && errors.email ? (
                                            <Col className="error-message">{errors.email}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="subject">

                                    <Form.Label column sm="3">Subject :</Form.Label>

                                    <Col sm="8">
                                        <Form.Control type="text" name="subject" placeholder="Enter Your Subject" onChange={handleChange} value={values.subject} style={{ borderColor: touched.subject && errors.subject ? "red" : null }} />
                                        {touched.subject && errors.subject ? (
                                            <Col className="error-message">{errors.subject}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Form.Group className="mb-3" controlId="message">

                                    <Form.Label column sm="3">Message :</Form.Label>

                                    <Col>
                                        <Form.Control as="textarea" name="message" rows={3} placeholder="Enter Your Message Here....." onChange={handleChange} value={values.message} style={{ borderColor: touched.message && errors.message ? "red" : null }} />
                                        {touched.message && errors.message ? (
                                            <Col className="error-message">{errors.message}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Modal.Footer className="modal-footer">
                                    <Button variant="primary" size="sm" type="submit" disabled={isSubmitting}><i className="bi bi-send" /></Button>
                                    {/* <Button variant="danger" size="sm" onClick={handleCloseContact} ><i className="bi bi-trash3" /></Button> */}
                                </Modal.Footer>

                            </Form>
                        )}

                    </Formik>
                </Modal.Body>

            </Modal>

        </>

    )
}

export default ImageSlider
import React, { useEffect } from 'react'
import MainImage from "../../images/MainImg2.jpg"
import WebHosting1 from "../../images/services/web-hosting1.png"
import WebHosting2 from "../../images/services/web-hosting2.gif"
import Aos from 'aos'

import 'aos/dist/aos.css'
import "../../css/service.css"

const WebHosting = () => {

    document.title = "Oxpan Microsys | Web Hosting"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    return (

        <>

            <div className='main-section' data-aos="fade-down">
                <img src={MainImage} alt="main" className='main-img' />
                <h2>Website Hosting</h2>
                <h3>Best SMS Service For Your Business....</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>Web Hosting</span></h2><hr />
            </div>

            <div className="intro-content">
                <div className="content-p" data-aos="fade-right">

                    <p>
                        We provide facilities to publish your website on the internet. Our team is ready to create your website and host it on the internet at a suitable price. Additionally, the Oxpan team can update your website as per your requirements and provide the best services to our clients.
                    </p>

                </div>

                <div className="content-img" data-aos="fade-down">
                    <img src={WebHosting2} alt="webhosting" />
                </div>

            </div>

            <div className="features" data-aos="fade-right">
                <h2><span>Our Features</span></h2><hr />
            </div>

            <div className='service-content'>

                <div className="service-content-list">

                    <ul className="accordion-service">

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="one" />
                            <label for="one">Attractive User Interface</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="two" />
                            <label for="two">Timely Deliver</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="three" />
                            <label for="three">User Friendly</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="four" />
                            <label for="four">Quality Product</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="five" />
                            <label for="five">SEO</label>

                            <div className="content-service1">

                            </div>

                        </li>

                    </ul>

                </div>

                <div className="service-content-img" data-aos="fade-down">
                    <img src={WebHosting1} alt="web hosting 1" />
                </div>

            </div>

        </>

    )
}

export default WebHosting
export const Questions = [
    {
        id: "one",
        Q: "What should we do if our client computer didn't open OXPAN Cooperative Software?",
        A: "Please be sure the server computer must be on. And make sure, in login page of OXPAN Software, the name of server must be the name of server computer."
    },
    {
        id: "two",
        Q: "What should we do if we forget our OXAPN cooperator goldplus password?",
        A: "For security issue we didn't public the answer please contact in office near you."
    },
    {
        id: "three",
        Q: "What is the price of your cooperative software?",
        A: "Please fill up the contact us form. We will sent you a quotation of our software after that we go for next step like software demo."
    },
    {
        id: "four",
        Q: "When are you going to lunch your BAncose Product?",
        A: "We are in testing phase. If we get as good result we will publish it. Please touch with us for further details."
    },
    {
        id: "five",
        Q: "What should we do it OXPAN  software didtn't open in our branch office?",
        A: "Please be sure the Internet must be connected in your server computer."
    },
    {
        id: "six",
        Q: "What is the price of your new product Bancose?",
        A: "It is available with affortable price . Don't Worry.."
    },
    {
        id: "seven",
        Q: "We need training about your dugdha software?",
        A: "We are available for you at any time. Please confirm you date for training by contacting our office which is near for you."
    },
    {
        id: "eight",
        Q: "Is there any product of Attendance Management System in your Organization?",
        A: "We don't have AMS currently. If you want then lets discuss for it."
    },
];

import React, { useEffect } from 'react'
import MainImage from "../../images/MainImg2.jpg"
import SmsService4 from "../../images/services/sms-service4.gif"
import SmsService3 from "../../images/services/sms-service3.png"
import Aos from 'aos'

import 'aos/dist/aos.css'
import "../../css/service.css"

const SmsService = () => {

    document.title = "Oxpan Microsys | SMS Service"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    return (

        <>

            <div className='main-section' data-aos="fade-down">
                <img src={MainImage} alt="main" className='main-img' />
                <h2>SMS Service</h2>
                <h3>Best SMS Service For Your Business....</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>SMS Service</span></h2><hr />
            </div>

            <div className="intro-content">
                <div className="content-p" data-aos="fade-right">

                    <p>
                        Oxpan Team provides SMS facilities to our clients, which help make their businesses more useful and reliable. Our clients use this feature for sending bulk messages, deposit and loan notifications, and more.
                    </p>

                </div>

                <div className="content-img" data-aos="fade-down">
                    <img src={SmsService4} alt="smsservice" />
                </div>

            </div>

            <div className="features" data-aos="fade-right">
                <h2><span>Our Features</span></h2><hr />
            </div>

            <div className='service-content'>

                <div className="service-content-list">

                    <ul className="accordion-service">

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="one" />
                            <label for="one">Unlimited Messages</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="two" />
                            <label for="two">Time Flexible</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="three" />
                            <label for="three">Rechargeable</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="four" />
                            <label for="four">Flexible Price</label>

                            <div className="content-service1">

                            </div>

                        </li>

                    </ul>

                </div>

                <div className="service-content-img" data-aos="fade-down">
                    <img src={SmsService3} alt="sms service 3" style={{ backgroundColor: "white" }} />
                </div>

            </div>

        </>

    )
}

export default SmsService
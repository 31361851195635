import React, { useEffect } from 'react'
import MainImage from "../../images/MainImg2.jpg"
import Vacancy from "../../images/vacancy1.png"
import VacancyLocation from "../../images/map.jpg"
import Aos from 'aos'

import 'aos/dist/aos.css'

const Career = () => {

    document.title = "Oxpan Microsys | Career"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    return (

        <>

            <div className='main-section' data-aos="fade-down">
                <img src={MainImage} alt="main" className='main-img' />
                <h2>Your Career</h2>
                <h3>Make Your Career Success With Us....</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>Career</span></h2><hr />
            </div>

            <div className='career-1' data-aos="fade-right">   

                <h4 className="h4-service-content">1. सफ्टवेयर  वितरक / प्राविधिकको आवस्यकता (तलव वा व्यापारिक हिस्सेदारीमा)</h4>

                <div className='service-content'>

                    <div className="service-content-list" data-aos="fade-right">
                        
                        <ul className="accordion-service">

                            <li>
                                <input type="radio" name="accordion-service" id="six" />
                                <label for="six">शैक्षिक योग्यता</label>
                                <div className="content-service1">
                                    <li>Bachelor in Computer/Management/Accounting</li>
                                </div>
                            </li>

                            <li>
                                <input type="radio" name="accordion-service" id="seven" />
                                <label for="seven">विशेष ग्राह्यता</label>
                                <div className="content-service1">
                                    <li>१. न्युनतम २ बर्ष सहकारी क्षेत्रमा काम गरेको</li>
                                    <li>२. Computer/Accounting मा तालिम लिएको</li>
                                    <li>३. स्थानीय वासिन्दा</li>
                                    <li>४. सवारी साधन र सवारी चालक अनुमतिपत्र भएको</li>
                                </div>
                            </li>

                            <li>
                                <input type="radio" name="accordion-service" id="eight" />
                                <label for="eight">कार्य योग्यता विवरण</label>
                                <div className="content-service1">
                                    <li>१. Computer Format and System Installation</li>
                                    <li>२. सहकारी सफ्टवेयर जडान</li>
                                    <li>३. व्यापारिक सफ्टवेयर जडान</li>
                                    <li>४. ग्राहकलाई आवस्यक तालिम प्रदान गर्ने</li>
                                    <li>५. Technical Support</li>
                                    <li>६. Reporting/Agreement/Documentation</li>
                                    <li>७. Software Promotion and Publicity</li>
                                </div>
                            </li>

                            <li>
                                <input type="radio" name="accordion-service" id="nine" />
                                <label for="nine">कार्य स्थानहरु</label>
                                <div className="content-service1">
                                    <li>१. जनकपुर</li>
                                    <li>२. वीरगञ्ज, हेटौडा</li>
                                    <li>३. नारायणगढ</li>
                                    <li>४. पोखरा</li>
                                    <li>५. बुटवल</li>
                                    <li>६. दाङ</li>
                                    <li>७. नेपालगन्ज, सुर्खेत</li>
                                    <li>८. धनगदी, दिपायल</li>
                                </div>
                            </li>

                        </ul>

                    </div>

                    <div className="content-img" data-aos="fade-down">
                        <img src={VacancyLocation} alt="career" />
                    </div>

                </div>

            </div>

            <div data-aos="fade-right">

                <h4 className="h4-service-content">2. Junior Software Developer/Technical Support/Trainer</h4>

                <div className="service-content">

                    <div className="service-content-list" data-aos="fade-right">

                        <ul className="accordion-service">

                            <li>

                                <input type="radio" name="accordion-service" id="one" />
                                <label for="one">JOB DESCRIPTION</label>

                                <div className="content-service1">

                                    <li>Installing Operating System</li>
                                    <li>Manage Local Area Network</li>
                                    <li>Operate Smart devices like, Tablets, POS Machines and Printers and Xerox machines, projectors</li>
                                    <li>Install and manage MS SQL Server 2008 R2 and newer versions of MS SQL Server</li>
                                    <li>Prepare hand-outs, booklets, presentations and manuals and other training materials</li>
                                    <li>Collect client requirements and complaints</li>

                                </div>

                            </li>

                            <li>

                                <input type="radio" name="accordion-service" id="two" />
                                <label for="two">QUALIFICATION</label>

                                <div className="content-service1">

                                    <li>Bacholer Dregree in relevent field OR</li>
                                    <li>Trainning from any institute</li>

                                </div>

                            </li>

                            <li>

                                <input type="radio" name="accordion-service" id="three" />
                                <label for="three">EXPERIENCE</label>

                                <div className="content-service1">

                                    <li>More than 6 Months in technical support</li>
                                    <li>Well knowledge of SQL Server and its Query</li>
                                    <li>Experience in HTML, CSS and JavaScript</li>

                                </div>

                            </li>

                            <li>

                                <input type="radio" name="accordion-service" id="four" />
                                <label for="four">OTHER SPECIFICATIONS</label>

                                <div className="content-service1">

                                    <li>Sound communication and interpersonal skill</li>
                                    <li>Basic concept of web and desktop designing and programming</li>
                                    <li>Sound Knowledge of Banking and Cooperative Transactions and Accounting</li>
                                    <li>Sound knowledge of operating system and application software Installation and troubleshooting</li>
                                    <li>Should have two wheeler valid licence </li>

                                </div>

                            </li>

                            <li>

                                <input type="radio" name="accordion-service" id="five" />
                                <label for="five">HOW TO APPLY</label>

                                <div className="content-service1">

                                    <li>Send your CV at oxpanmicrosys@gmail.com</li>
                                    <li>Send Cover letter with expected Salary in same Mail ID.</li>
                                    
                                </div>

                            </li>

                        </ul>

                    </div>

                    <div className="service-content-img" data-aos="fade-down">
                        <img src={Vacancy} alt="career" />
                    </div>

                </div>

            </div>

        </>

    )
}

export default Career
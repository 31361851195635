import React, { useEffect } from 'react'
import { Carousel } from 'react-bootstrap'
import MainImage from "../../images/MainImgCoponext.jpeg"
import Coponext1 from "../../images/products/coponext1.png"
import Coponext2 from "../../images/products/coponext2.png"
import Coponext3 from "../../images/products/coponext3.png"
import Aos from 'aos'
import {
    FollowNationalIntRules,
    SufficientToAllCoop,
    ModernBankingFac,
    AccurateAndScientificCalc,
    FlexibleAndScalable,
    SecurityMgmt,
    EfficientDBMS,
    EasyAndAttractive,
    PlentyOfReportingTools,
    NepaliReports,
    ScheduledClosing,
    InterbankTransaction,
    RegularUpdatesAndDevelopment,
    UrgentReport,
    FinancialAnalyticalTools,
    SelfDesigning,
    MinimizedSteps,
    AutomaticPeriodClosing,
    MobileBanking,
    UpgradedFeatures
} from './GoldplusFeaturesList'

import "../../css/goldplus.css"
import 'aos/dist/aos.css'

const CopoNext = () => {

    document.title = "Oxpan Microsys | CopoNext"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    return (

        <>

            {/* <div className="coming-soon">
                <img src={ComingSoon1} alt="coming soon" />
            </div> */}

            <div className='main-section' data-aos="fade-down">
                <img src={MainImage} alt="main" className='main-img' />
                <h2>OXPAN COPONEXT</h2>
                <h3>"More Secure & Attractive Web Version <br /> for saving and credit cooperatives"</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>Introduction</span></h2><hr />
            </div>

            <div className="intro-content">
                <div className="content-p" data-aos="fade-right">

                    <p>
                        Oxpan Coponext is the web-based version of Oxpan Bancos banking and accounting software, specially designed for cooperatives. It offers all the advanced features and modern technology of the desktop version, with the added benefit of being accessible from anywhere with an internet connection. Oxpan Coponext enables cooperatives to manage their financial operations on the go and collaborate with their team members in real-time. It supports multiple user accounts and is compatible with a variety of web browsers, making it accessible to a wider range of users. With Oxpan Coponext, cooperatives can enjoy the convenience and flexibility of web-based software while still benefiting from the powerful features of Oxpan Bancos.
                    </p>
                    <p>
                        It offers advanced features and modern technology while providing the convenience and flexibility of web-based software. The system is designed with security in mind and employs encryption technology, multi-factor authentication, and role-based access control to ensure that only authorized users can access the system and sensitive data is transmitted securely. The software is regularly monitored and audited to detect potential security threats or vulnerabilities, making it a reliable and secure solution for cooperatives managing their financial operations online.
                    </p>

                </div>

                <div className="content-img" data-aos="fade-down">
                    {/* <img src={null} alt="bancos" /> */}
                    <Carousel fade interval={3000} controls={false} indicators={false}>
                        <Carousel.Item>
                            <img src={Coponext1} alt="coponext1" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={Coponext2} alt="coponext2" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={Coponext3} alt="coponext3" />
                        </Carousel.Item>
                    </Carousel>
                </div>

            </div>

            <div className="features" data-aos="fade-right">
                <h2><span>Features Of OXPAN Coponext</span></h2><hr />
            </div>

            <div className=''>

                <ul className="accordion">

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="zero-0" />
                        <label for="zero-0" className="label">Works Easily From Web Browsers</label>

                        <div className="content">

                            <p>Oxpan Coponext supports most of the browsers. You can access it from your desktops, laptops and even from mobile devices.</p>

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="zero-1" />
                        <label for="zero-1" className="label">Maintain High Security & Availability</label>

                        <div className="content">

                            <p>
                                Oxpan Coponext is developed with high security features. All the transaction done through Coponext is secure. It is designed to run in most of the devices used now a days.</p>

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="zero" />
                        <label for="zero" className="label">New Features</label>

                        <div className="content">

                            {UpgradedFeatures.map((item0) => {
                                return (
                                    <>
                                        <li>{item0.name}</li>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="one" />
                        <label for="one" className="label">Follows National And International Rules</label>

                        <div className="content">

                            {FollowNationalIntRules.map((item1) => {
                                return (
                                    <>
                                        <li>{item1.name}</li>
                                        <p>{item1.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="two" />
                        <label for="two" className="label">Sufficient to All Cooperative</label>

                        <div className="content">

                            {SufficientToAllCoop.map((item2) => {
                                return (
                                    <>
                                        <li>{item2.name}</li>
                                        <p>{item2.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="three" />
                        <label for="three" className="label">Modern Banking Facilities</label>

                        <div className="content">

                            <text className="text">OXPAN Cooperator has following attractive banking facilities:</text>

                            {ModernBankingFac.map((item3) => {
                                return (
                                    <>
                                        <li>{item3.name}</li>
                                        <p>{item3.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="four" />
                        <label for="four" className="label">Accurate and Scientific Calculations</label>

                        <div className="content">

                            {AccurateAndScientificCalc.map((item4) => {
                                return (
                                    <>
                                        <li>{item4.name}</li>
                                        <p>{item4.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="five" />
                        <label for="five" className="label">Flexible and Scalable</label>

                        <div className="content">

                            {FlexibleAndScalable.map((item5) => {
                                return (
                                    <>
                                        {/* <li>{item5.name}</li> */}
                                        <p>{item5.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="six" />
                        <label for="six" className="label">Security Management</label>

                        <div className="content">

                            {SecurityMgmt.map((item6) => {
                                return (
                                    <>
                                        {/* <li>{item6.name}</li> */}
                                        <p>{item6.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="seven" />
                        <label for="seven" className="label">Efficient Database Management System</label>

                        <div className="content">

                            {EfficientDBMS.map((item7) => {
                                return (
                                    <>
                                        {/* <li>{item7.name}</li> */}
                                        <p>{item7.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="eight" />
                        <label for="eight" className="label">Easy and Attractive</label>

                        <div className="content">

                            {EasyAndAttractive.map((item8) => {
                                return (
                                    <>
                                        {/* <li>{item8.name}</li> */}
                                        <p>{item8.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="nine" />
                        <label for="nine" className="label">Plenty of Reporting Tools</label>

                        <div className="content">

                            <text className="text">OXPAN Software is rich of bundles of reports which are very useful for Managerial team and other stakeholders.</text>

                            {PlentyOfReportingTools.map((item9) => {
                                return (
                                    <>
                                        <li>{item9.name}</li>
                                        <p>{item9.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="ten" />
                        <label for="ten" className="label">Nepali Reports</label>

                        <div className="content">

                            {NepaliReports.map((item10) => {
                                return (
                                    <>
                                        {/* <li>{item10.name}</li> */}
                                        <p>{item10.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="eleven" />
                        <label for="eleven" className="label">Scheduled Closing, Calculations, Provisions and Backup</label>

                        <div className="content">

                            {ScheduledClosing.map((item11) => {
                                return (
                                    <>
                                        {/* <li>{item11.name}</li> */}
                                        <p>{item11.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="twelve" />
                        <label for="twelve" className="label">Interbranch Transaction</label>

                        <div className="content">

                            {InterbankTransaction.map((item12) => {
                                return (
                                    <>
                                        {/* <li>{item12.name}</li> */}
                                        <p>{item12.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="thirteen" />
                        <label for="thirteen" className="label">Regular Updates and Development</label>

                        <div className="content">

                            {RegularUpdatesAndDevelopment.map((item13) => {
                                return (
                                    <>
                                        {/* <li>{item13.name}</li> */}
                                        <p>{item13.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="fourteen" />
                        <label for="fourteen" className="label">Urgent Report Facility</label>

                        <div className="content">

                            {UrgentReport.map((item14) => {
                                return (
                                    <>
                                        {/* <li>{item14.name}</li> */}
                                        <p>{item14.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="fifteen" />
                        <label for="fifteen" className="label">Financial Analytical Tools</label>

                        <div className="content">

                            <text className="text">OXPAN Cooperator has very useful tools such as:</text>

                            {FinancialAnalyticalTools.map((item15) => {
                                return (
                                    <>
                                        <li>{item15.name}</li>
                                        <p>{item15.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="sixteen" />
                        <label for="sixteen" className="label">Self Designings</label>

                        <div className="content">

                            {SelfDesigning.map((item16) => {
                                return (
                                    <>
                                        {/* <li>{item16.name}</li> */}
                                        <p>{item16.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="seventeen" />
                        <label for="seventeen" className="label">Minimized Steps</label>

                        <div className="content">

                            {MinimizedSteps.map((item17) => {
                                return (
                                    <>
                                        {/* <li>{item17.name}</li> */}
                                        <p>{item17.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="eighteen" />
                        <label for="eighteen" className="label">Automatic Periodic Closing and Reporting</label>

                        <div className="content">

                            {AutomaticPeriodClosing.map((item18) => {
                                return (
                                    <>
                                        <li>{item18.name}</li>
                                        <p>{item18.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="nineteen" />
                        <label for="nineteen" className="label">Mobile Banking Facility</label>

                        <div className="content">

                            <text className="text">OXPAN Software provides facility to join with various mobile banking facilities like:</text>

                            {MobileBanking.map((item19) => {
                                return (
                                    <>
                                        <li>{item19.name}</li>
                                        <p>{item19.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                </ul>
            </div>

        </>

    )
}

export default CopoNext
import React from 'react'

import "../css/categorystats.css"

const CategoryStats = () => {

    return (

        <>

            <div className="category-stats">

                <div className="inner grid-x">

                    <div className="cell small-4 text-center">
                        1500+
                        <span className="stat-text">Clients</span>
                    </div>
                    <div className="cell small-4 text-center">
                        15+
                        <span className="stat-text">Products</span>
                    </div>
                    <div className="cell small-4 text-center">
                        7
                        <span className="stat-text">Provinces</span>
                    </div>

                </div>

            </div>

        </>

    )
}

export default CategoryStats
import React, { useEffect } from 'react'
import MainImage from "../../images/MainImg2.jpg"
import Oxpan2 from "../../images/products/products.jpg"
import Oxpan1 from "../../images/gif/Goldplus56.gif"
import Oxpan3 from "../../images/services/services.jpg"
import Oxpan4 from "../../images/products/bancos-home.png"
import Oxpan5 from "../../images/products/bancos-dashboard.png"
import Aos from 'aos'
import PartnerSlider from '../PartnerSlider'

import 'aos/dist/aos.css'
import { Carousel } from 'react-bootstrap'


const AboutUs = () => {

    document.title = "Oxpan Microsys | About Us"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    return (

        <>

            <div className='main-section' data-aos="fade-down">
                <img src={MainImage} alt="main" className='main-img' />
                <h2>About Us</h2>
                <h3>Best Cooperative Software For Your Organization....</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>Oxpan Microsys</span></h2><hr />
            </div>

            <div className="intro-content">
                <div className="content-p" data-aos="fade-right">

                    <p>
                        OXPAN Microsys is well known software provider for cooperative banking. More than 1500 financial institution are currently using OXPAN Softwares in their workplaces over the nation. It has five different packages for cooperative socities with full package. Apart from cooperative software it has accounting software of trading business, social organizations, export import, carrying business and factory business. School management software and hospital management softwares are in under construction. On demand software development facility is also available. OXPAN software are mostly popular for attractive and user friendly interface, perfectness, reliable, accounting standard, scalable and robust and strong and technology. It is established in 2005 to provide high quality software for enterprises of various types. Its main office is at Kathmandu, New Baneshwor. Other sales and support centers are in Birtamode, Itahari, Birgunj, Banepa and Nepalgunj.
                    </p>

                </div>

                <div className="content-img" data-aos="fade-down">
                    {/* <img src={Oxpan2} alt="oxpan1" /> */}
                    <Carousel fade interval={3000} controls={false} indicators={false}>
                        <Carousel.Item>
                            <img src={Oxpan1} alt="oxpan1" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={Oxpan4} alt="oxpan4" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={Oxpan5} alt="oxpan5" />
                        </Carousel.Item>
                    </Carousel>
                </div>

            </div>

            <div className="features" data-aos="fade-right">
                <h2><span>Our Products</span></h2><hr />
            </div>

            <div className='service-content'>

                <div className="service-content-list" data-aos="fade-right">

                    <ul className="accordion-service">

                        <li>

                            <input type="radio" name="accordion-service" id="one" />
                            <label for="one">Cooperator Gold Plus</label>

                            <div className="content-service1"></div>

                        </li>

                        <li>

                            <input type="radio" name="accordion-service" id="two" />
                            <label for="two">CopoNext</label>

                            <div className="content-service1"></div>

                        </li>

                        <li>

                            <input type="radio" name="accordion-service" id="three" />
                            <label for="three">Bancos</label>

                            <div className="content-service1"></div>

                        </li>

                        <li>

                            <input type="radio" name="accordion-service" id="four" />
                            <label for="four">Cooperator Dudgha</label>

                            <div className="content-service1"></div>

                        </li>

                        <li>

                            <input type="radio" name="accordion-service" id="five" />
                            <label for="five">AllMart</label>

                            <div className="content-service1"></div>

                        </li>

                        <li>

                            <input type="radio" name="accordion-service" id="six" />
                            <label for="six">CoopMart</label>

                            <div className="content-service1"></div>

                        </li>

                        <li>

                            <input type="radio" name="accordion-service" id="seven" />
                            <label for="seven">MiCOS</label>

                            <div className="content-service1"></div>

                        </li>

                    </ul>

                </div>

                <div className="service-content-blank"><span></span></div>

                <div className="service-content-img" data-aos="fade-down">
                    <img src={Oxpan2} alt="oxpan2" style={{ marginLeft: "2rem" }} />
                </div>

            </div>

            <div className="features" data-aos="fade-right">
                <h2><span>Our Services</span></h2><hr />
            </div>

            <div className='service-content'>

                <div className="service-content-list" data-aos="fade-right">

                    <ul className="accordion-service">

                        <li>

                            <input type="radio" name="accordion-service" id="eight" />
                            <label for="eight">Desktop Service</label>

                            <div className="content-service1"></div>

                        </li>

                        <li>

                            <input type="radio" name="accordion-service" id="nine" />
                            <label for="nine">Cloud Service</label>

                            <div className="content-service1"></div>

                        </li>

                        <li>

                            <input type="radio" name="accordion-service" id="ten" />
                            <label for="ten">SMS Service</label>

                            <div className="content-service1"></div>

                        </li>

                        <li>

                            <input type="radio" name="accordion-service" id="eleven" />
                            <label for="eleven">Mobile Banking</label>

                            <div className="content-service1"></div>

                        </li>

                        <li>

                            <input type="radio" name="accordion-service" id="twelve" />
                            <label for="twelve">Website Development</label>

                            <div className="content-service1"></div>

                        </li>

                        <li>

                            <input type="radio" name="accordion-service" id="thirteen" />
                            <label for="thirteen">Android App Development</label>

                            <div className="content-service1"></div>

                        </li>

                        <li>

                            <input type="radio" name="accordion-service" id="fourteen" />
                            <label for="fourteen">Web Hosting</label>

                            <div className="content-service1"></div>

                        </li>

                    </ul>

                </div>

                <div className="service-content-blank"><span></span></div>

                <div className="service-content-img" data-aos="fade-down">
                    <img src={Oxpan3} alt="oxpan3" style={{ marginLeft: "2rem" }} />
                </div>

            </div>

            <div className="p1" data-aos="fade-right">
                <PartnerSlider />
            </div>

        </>

    )
}

export default AboutUs
import React, { useEffect } from 'react'
import MainImage from "../../images/MainImg2.jpg"
import Aos from 'aos'
import { Questions } from './QuestionsAnswer'

import 'aos/dist/aos.css'

const FAQs = () => {

    document.title = "Oxpan Microsys | FAQs"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    return (

        <>

            <div className='main-section' data-aos="fade-down">
                <img src={MainImage} alt="main" className='main-img' />
                <h2>FAQs</h2>
                <h3>Frequently Asked Questions....</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>Frequently Asked Questions</span></h2><hr />
            </div>

            <div className="" data-aos="fade-right">

                <ul className="accordion">

                    {Questions.map((item) => {
                        return (
                            <li>

                                <input type="radio" name="accordion" id={item.id} />
                                <label for={item.id} className="label">{item.Q}</label>
                                <div className="content">
                                    <li>{item.A}</li>
                                </div>

                            </li>
                        )
                    })}

                </ul>

            </div>

        </>

    )
}

export default FAQs
import React from 'react'
import ComingSoon from "../../images/gif/ComingSoon.gif"

const DownloadForms = () => {

    return (

        <>

            <div className="coming-soon">
                <img src={ComingSoon} alt="coming soon" />
            </div>

        </>

    )
}

export default DownloadForms
import React from 'react'
// import Advert1 from "../../images/gif/advertise1.WebM"
import Advert2 from "../../images/gif/advertise1.gif"

const Adv1 = () => {

  // const videoRef = useRef(null);

  // const handleVideoEnd = () => {
  //   videoRef.current.currentTime = 0;
  //   videoRef.current.play();
  // }

  return (

    <>

      <div className='advertise'>
        <a href='/company/career'>
          {/* <video autostart autoPlay muted ref={videoRef} onEnded={handleVideoEnd} src={Advert1} type="video/WebM" /> */}
          <img src={Advert2} alt='Advertise' />
        </a>
      </div>

    </>

  )
}

export default Adv1
import React, { useEffect } from 'react'
import MainImage from "../../images/MainImg2.jpg"
import CoopMart2 from "../../images/products/CoopMart.png"
import Aos from 'aos'
import {
    SupportsMany,
    IntegrationWithCoop,
    FollowsRules,
    ModernFac,
    EasyAndAttMart,
    PlentyOfReportsMart,
    FinancialAnalyticalToolsMart,
    SelfDesigningMart
} from './MartFeaturesList'
import {
    SecurityMgmt,
    EfficientDBMS,
    ScheduledClosing,
    RegularUpdatesAndDevelopment,
    MinimizedSteps
} from './GoldplusFeaturesList'

import 'aos/dist/aos.css'

const CoopMart = () => {

    document.title = "Oxpan Microsys | CoopMart"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    return (

        <>

            <div className='main-section' data-aos="fade-down">
                <img src={MainImage} alt="main" className='main-img' />
                <h2>OXPAN CoopMart</h2>
                <h3>"Cooperative Integrated Trading Software"</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>Introduction</span></h2><hr />
            </div>

            <div className="intro-content">
                <div className="content-p" data-aos="fade-right">

                    <p>
                        We can conduct shopping for our members by providing them with an account ID and card. This allows them to purchase from the mart and pay from their account with a discount. We offer this service to cooperatives for their customers. Cooperatives can either purchase from their members or sell to their members, or do both. Sales book, purchase book, stock, profit and loss are all integrated with the cooperative. Cooperatives that are involved in purchase and sales or sales only (service providers) need OXPAN CoopMart for proper care of their business activities. A cooperative shop is initially run with capital from the cooperative, then the business starts making the workplace or factory, purchases goods and stores them, and delivers the goods to shopping centers or factories.
                    </p>
                    <p>
                        New goods may also be prepared and sent to the store, and sold to customers, or services may be created and sold to customers. All of these activities are aimed at making a good profit and progress for the business. Every step of these economic activities should be recorded so that progress reports can be generated. This includes determining whether the business is earning or losing money, what the rate of earning is, and whether it is satisfactory or not. Such type of care can be provided by a proper software. We have developed CoopMart for cooperatives that face trouble maintaining good books of accounts, records of stocks, and receivables and payables. It was developed by an expert team of developers under regular collaboration and guidance of specialists and chartered accountants. The double-entry accounting system is used in CoopMart along with the charkhata of the cooperative. OXPAN CoopMart is applicable to all versions of Microsoft Windows in a networking or single computer environment, as well as for businesses with multi-user environments or multiple branches.
                    </p>

                </div>

                <div className="content-img" data-aos="fade-down">
                    <img src={CoopMart2} alt="coopmart" />
                </div>

            </div>

            <div className="features" data-aos="fade-right">
                <h2><span>Features Of OXPAN CoopMart</span></h2><hr />
            </div>

            <div className=''>

                <ul className="accordion">

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="zero" />
                        <label for="zero" className="label">Supports many types of shops and trading houses</label>

                        <div className="content">

                            {SupportsMany.map((item) => {
                                return (
                                    <>
                                        <li>{item.name}</li>
                                        <p>{item.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="one" />
                        <label for="one" className="label">Integration With Cooperative</label>

                        <div className="content">

                            {IntegrationWithCoop.map((item1) => {
                                return (
                                    <>
                                        <li>{item1.name}</li>
                                        <p>{item1.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="two" />
                        <label for="two" className="label">Follows National and International Rules</label>

                        <div className="content">

                            {FollowsRules.map((item2) => {
                                return (
                                    <>
                                        <li>{item2.name}</li>
                                        <p>{item2.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="three" />
                        <label for="three" className="label">Modern Shopping Facilities</label>

                        <div className="content">

                            {ModernFac.map((item3) => {
                                return (
                                    <>
                                        <li>{item3.name}</li>
                                        <p>{item3.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="four" />
                        <label for="four" className="label">Accurate and Scientifice Calculations</label>

                        <div className="content">

                            {/* {AccurateAndScientificCalc.map((item4) => {
                                return (
                                    <>
                                        <li>{item4.name}</li>
                                        <p>{item4.desc}</p>
                                    </>
                                )
                            })} */}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="five" />
                        <label for="five" className="label">Security Management</label>

                        <div className="content">

                            {SecurityMgmt.map((item5) => {
                                return (
                                    <>
                                        {/* <li>{item5.name}</li> */}
                                        <p>{item5.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="six" />
                        <label for="six" className="label">Efficient Database Management System</label>

                        <div className="content">

                            {EfficientDBMS.map((item6) => {
                                return (
                                    <>
                                        {/* <li>{item6.name}</li> */}
                                        <p>{item6.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="seven" />
                        <label for="seven" className="label">Easy and Attractive</label>

                        <div className="content">

                            {EasyAndAttMart.map((item7) => {
                                return (
                                    <>
                                        {/* <li>{item7.name}</li> */}
                                        <p>{item7.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="eight" />
                        <label for="eight" className="label">Plenty of Reporting Tools</label>

                        <div className="content">

                            <text className="text">OXPAN CoopMart has rich of bundles of reports which are very useful for Managerial team and other stakeholders.</text>

                            {PlentyOfReportsMart.map((item8) => {
                                return (
                                    <>
                                        <li>{item8.name}</li>
                                        {/* <p>{item8.desc}</p> */}
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="nine" />
                        <label for="nine" className="label">Scheduled Closing, Calculations, Provisions and Backup</label>

                        <div className="content">

                            {/* <text className="text">OXPAN Software is rich of bundles of reports which are very useful for Managerial team and other stakeholders.</text> */}

                            {ScheduledClosing.map((item9) => {
                                return (
                                    <>
                                        {/* <li>{item9.name}</li> */}
                                        <p>{item9.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="ten" />
                        <label for="ten" className="label">Regular Updates and Development</label>

                        <div className="content">

                            {RegularUpdatesAndDevelopment.map((item10) => {
                                return (
                                    <>
                                        {/* <li>{item10.name}</li> */}
                                        <p>{item10.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="eleven" />
                        <label for="eleven" className="label">Financial Analytical Tools</label>

                        <div className="content">

                            {FinancialAnalyticalToolsMart.map((item11) => {
                                return (
                                    <>
                                        <li>{item11.name}</li>
                                        {/* <p>{item11.desc}</p> */}
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="twelve" />
                        <label for="twelve" className="label">Self Designings</label>

                        <div className="content">

                            {SelfDesigningMart.map((item12) => {
                                return (
                                    <>
                                        {/* <li>{item12.name}</li> */}
                                        <p>{item12.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                    <li data-aos="fade-right">

                        <input type="radio" name="accordion" id="thirteen" />
                        <label for="thirteen" className="label">Minimized Steps</label>

                        <div className="content">

                            {MinimizedSteps.map((item13) => {
                                return (
                                    <>
                                        {/* <li>{item13.name}</li> */}
                                        <p>{item13.desc}</p>
                                    </>
                                )
                            })}

                        </div>

                    </li>

                </ul>

            </div>

        </>

    )
}

export default CoopMart
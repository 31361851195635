import React from 'react'
import '../css/partnerslider.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'

import "swiper/swiper.min.css";
import "swiper/css/pagination";

import MBank from '../images/partners/m-bank.png'
import Mofin from '../images/partners/MOFIN.png'
import CloudHimalaya from '../images/partners/cloud-himalaya.png'
import DataHub from '../images/partners/data-hub.png'
import SparrowSMS from '../images/partners/SparrowSMS1.jpg'

const PartnerSlider = () => {

    const PartnerList = [
        {
            partnerID: "1",
            partnerName: "M-BANK",
            partnerPhoto: MBank,
            partnerAlt: "oxpan demo"
        },
        {
            partnerID: "2",
            partnerName: "MOFIN",
            partnerPhoto: Mofin,
            partnerAlt: "oxpan demo"
        },
        {
            partnerID: "3",
            partnerName: "Cloud Himalaya",
            partnerPhoto: CloudHimalaya,
            partnerAlt: "oxpan demo"
        },
        {
            partnerID: "4",
            partnerName: "Data Hub",
            partnerPhoto: DataHub,
            partnerAlt: "oxpan demo"
        },
        {
            partnerID: "5",
            partnerName: "Sparrow SMS",
            partnerPhoto: SparrowSMS,
            partnerAlt: "oxpan demo"
        }
    ]

    return (

        <>
            <div className="partners">
                <h2><span>OUR PARTNERS</span></h2><hr />
            </div>
            <div className="partners-body">
                <h3>We Work With</h3>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={20}
                    // slidesPerGroup={1}
                    loop={true}
                    // centeredSlides={true}
                    // fadeEffect={true}
                    grabCursor={true}
                    pagination={{ clickable: true, dynamicBullets: true }}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1
                        },
                        670: {
                            slidesPerView: 2
                        },
                        950: {
                            slidesPerView: 3
                        },
                        1250: {
                            slidesPerView: 4
                        },
                    }}
                    modules={[Pagination, Autoplay]}
                    className="slide-container-partners">
                    <div className="slide-content-partners">
                        <div className="card-wrapper-partners">
                            {PartnerList.map((item, idx) => {
                                return (
                                    <SwiperSlide className="card shadow">
                                        {/* <div className="card-content-partners">
                                            <h4 className="name-partners" >{item.partnerName}</h4>
                                        </div> */}
                                        <div className="image-content-partners">
                                            <div className="card-image-partners">
                                                <img src={item.partnerPhoto} alt={item.partnerAlt} className="card-img-partners" />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </div>
                    </div>
                </Swiper>
            </div>
        </>

    )
}

export default PartnerSlider
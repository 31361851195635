export const AllDugdhaColl = [
    {
        name: "Members are farmers and Farmers are sellers",
        desc: "In Cooperator Dugdha after creating membership farmers accounts should be created. Farmers accounts are the milk producters milk collection accounts."
    },
    {
        name: "Milk Collections",
        desc: "Milk Collection entries are made from collection centers in mass basis. Collection date, collected quantity and quality is entered in the system."
    },
    {
        name: "Periodic Calculation",
        desc: "Calculations are made and amounts are collected in farmers deposit accounts."
    },
    {
        name: "Collection Statements",
        desc: "Individual or mass collection statements can be generated within seconds."
    },
    {
        name: "Paysheet",
        desc: "To make payment in mass paysheet is generated. After Collection entry, After Collection they can be automatically enter the collection in Cooperator Dugdha"
    },
    {
        name: "Other Milk Activities",
        desc: ""
    },
];

export const SufficientToAllMicro = [
    {
        name: "Any scale",
        desc: "Single computer cooperative to multi branch cooperative."
    },
    {
        name: "Microfinance",
        desc: "Integrated with cooperative."
    },
];

export const EasyAndAttMicro = [
    {
        desc: "OXPAN Cooperator Dugdha is the software which teaches you what a microfinance is, what a accounting system is and what we a software is. You learn entire microfinance by using this software. This is the software from which you can learn and enjoy working."
    }
];

export const AllMicroPractice = [
    {
        name: "Members are organized in group",
        desc: "All Members in microfinance are organized in groups and subgroups. We can easily create collection centers and groups. Memebers are added in those groups."
    },
    {
        name: "Collection Centers",
        desc: "Collection centers can be defined in MiCOS."
    },
    {
        name: "Schedule Meeting",
        desc: "Meeting is scheduled for the collection centers."
    },
    {
        name: "Scheduled Collection",
        desc: "Deposit and loans in the microfinance groups are collected as per the scheduled by the cooperative for that collection center in a particular interval (monthy,  weekly) .Depreciations: Depreciation: Depreciation is deducted as per the rules."
    },
    {
        name: "Auto Generate Collection Sheet",
        desc: "As per the schedule collection sheets are generated and can be printed out on hard copy to send to collection centers."
    },
    {
        name: "After Collection entry",
        desc: "After Collection they can be automatically enter the collection in MiCOS"
    },
    {
        name: "Group Saving and Group Fund",
        desc: ""
    },
];

export const EasyAndAttMicro2 = [
    {
        desc: "OXPAN Cooperator MiCOS is the software which teaches you what a microfinance is, what a accounting system is and what we a software is. You learn entire microfinance by using this software. This is the software from which you can learn and enjoy working."
    }
];

import React, { useEffect } from 'react'
import MainImage from "../../images/MainImg2.jpg"
import AndroidApp1 from "../../images/services/android-app-dev1.gif"
import AndroidApp2 from "../../images/services/android-app-dev2.webp"
import Aos from 'aos'

import 'aos/dist/aos.css'
import "../../css/service.css"

const AndroidAppDev = () => {

    document.title = "Oxpan Microsys | Android App Develpment"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    return (

        <>

            <div className='main-section' data-aos="fade-down">
                <img src={MainImage} alt="main" className='main-img' />
                <h2>Android App Development</h2>
                <h3>Best Android Application For Your Work....</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>Android App Development</span></h2><hr />
            </div>

            <div className="intro-content">
                <div className="content-p" data-aos="fade-right">

                    <p>
                        Oxpan is one of the best IT companies in Nepal. We specialize in transforming your project ideas into iOS or Android apps. In addition to app development, we also offer cooperative mobile banking services. Our team is dedicated to providing you with the best Android application service.
                    </p>

                </div>

                <div className="content-img" data-aos="fade-down">
                    <img src={AndroidApp1} alt="android app dev 1" />
                </div>

            </div>

            <div className="features" data-aos="fade-right">
                <h2><span>Our Features</span></h2><hr />
            </div>

            <div className='service-content'>

                <div className="service-content-list">

                    <ul className="accordion-service">

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="one" />
                            <label for="one">Timely Deliver</label>

                            <div className="content-service1"></div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="two" />
                            <label for="two">User Friendly</label>

                            <div className="content-service1"></div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="three" />
                            <label for="three">Attractive Features</label>

                            <div className="content-service1"></div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="four" />
                            <label for="four">Flexible Price</label>

                            <div className="content-service1"></div>

                        </li>

                    </ul>

                </div>

                <div className="service-content-img" data-aos="fade-down">
                    <img src={AndroidApp2} alt="android app dev 2" style={{ marginLeft: "2rem" }} />
                </div>

            </div>

        </>

    )
}

export default AndroidAppDev
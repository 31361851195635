export const FollowNationalIntRules = [
    {
        name: "Charkhata Accounting System",
        desc: "Charkhata is an accounting system developed for cooperative by its experts. This is well implemented in OXPAN cooperative solutions."
    },
    {
        name: "Nepali Date",
        desc: "OXPAN Cooperator follows the nepali calendars and fiscal years"
    },
    {
        name: "Tax Provisions",
        desc: "Income-tax, Tax Deduction in Source (TDS) are calculated automatically."
    },
    {
        name: "Provisions",
        desc: "Bad debts and other fund provisions are deducted as per the cooperative act and regulations."
    },
    {
        name: "Depreciations",
        desc: "Depreciation: Depreciation is deducted as per the rules."
    },
    {
        name: "Flexible Rates",
        desc: "Flexible to chage the rates and other provisions as per the government rules."
    },
    
];

export const SufficientToAllCoop = [
    {
        name: "Any type of cooperative",
        desc: "Supports Banking, Milk collection, Trading and manufacturing cooperatives."
    },
    {
        name: "Any scale",
        desc: "Single computer cooperative to multi branch cooperative."
    },
    {
        name: "Microfinance",
        desc: "Integrated with cooperative."
    },
];

export const ModernBankingFac = [
    {
        name: "Photo and signature Verification facility"
    },
    {
        name: "SMS banking"
    },
    {
        name: "Collection App for daily collection"
    },
    {
        name: "Cheque Print"
    },
    {
        name: "Deposit Certificates and Statements"
    },
    {
        name: "Inter branch transaction"
    },
];

export const AccurateAndScientificCalc = [
    {
        name: "Interest Calculations of Deposits in various methods",
        desc: "Daily Balance, Daily Minimum, Monthly Minimum, Month End Balance, Weekly Minimum, Half yearly minimun and Fixed."
    },
    {
        name: "Flexible Interest Rate",
        desc: "Flexible interest rates as on the deposit balance"
    },
    {
        name: "Penalty Calculations",
        desc: "On installment due, On interest due, on total due. various ways availabe."
    },
    {
        name: "Depreciation",
        desc: "Diminishing Balance, Straight line."
    },
    {
        name: "Provision for Bad Debts",
        desc: "1, 35, 100 as per the rules."
    },
];

export const FlexibleAndScalable = [
    {
        desc: "OXPAN software provides new ways for planning and implementation. It has the capability to extend your busines scale from one to millions and single computer to multiple branches."
    }
];

export const SecurityMgmt = [
    {
        desc: "Transaction Audit, Userbased roles, Data backup facility are the security features. These features prevents wrong data injection, wrong manipulations, disaster of data loss."
    }
];

export const EfficientDBMS = [
    {
        desc: "Well designed database management system provides efficient and fast reporting facilities to the end users."
    }
];

export const EasyAndAttractive = [
    {
        desc: "OXPAN is the software which teaches you what is cooperative, what a accounting system is and what we can a software is. You learn entire cooperative by using this software. This is the software from which you can learn and enjoy working."
    }
];

export const PlentyOfReportingTools = [
    {
        name: "Membership Detail and Account Details"
    },
    {
        name: "Complete Charkhata Ledger with sub ledger"
    },
    {
        name: "Branch-wise Central report regarding"
    },
    {
        name: "Deposit and loan collection report"
    },
    {
        name: "Deposit Balance and Transaction"
    },
    {
        name: "Loan Balance and Transactions"
    },
    {
        name: "Regular and overdue loan reports"
    },
    {
        name: "Overall transaction report"
    },
    {
        name: "Branch-wise and central report of Trial balance"
    },
    {
        name: "Balance sheet and income statement and cash flow statement."
    },
    {
        name: "Complete central division office report"
    },
    {
        name: "NRB format Reports"
    },
    {
        name: "Access Branding Reports"
    },
    {
        name: "Loan Aging Reports"
    },
    {
        name: "Financial Reports"
    },
    {
        name: "Daybooks and Transaction Sheets"
    },
    {
        name: "Progress Reports"
    },
    {
        name: "Comparative Periodic and subject-wise report"
    },
    {
        name: "Financial Analytical Tools"
    },
    {
        name: "MIS Reports"
    },
    {
        name: "Branch wise progress report."
    },
    {
        name: "COPOMIS Reports"
    },
    {
        name: "PROBATION Reports"
    },
    {
        name: "Remittance Reports"
    },
];

export const NepaliReports = [
    {
        desc: "You can get all reports in Nepali and English both languages."
    }
];

export const ScheduledClosing = [
    {
        desc: "We can shedule for automatic calculations, provision and closings in routined time interval."
    }
];

export const InterbankTransaction = [
    {
        desc: "This the features which provides facility to wrok with multiple branches. Cooperative with many services can be benifitted by this. We can make branchwise transaction, branchwise entry branch-wise reporting. It can be done using cloud server or own local server with public static IP."
    }
];

export const RegularUpdatesAndDevelopment = [
    {
        desc: "Your feedbacks are our properties. We collect feedbacks and suggestions, we get new ideas we discuss and beging planning. New features are developed in regular basis and provided back to the end user customers."
    }
];

export const UrgentReport = [
    {
        desc: "Current liquidity, Receivables, payables, and other emergency reports can generate in seconds."
    }
];

export const FinancialAnalyticalTools = [
    {
        name: "PEARLS Analysis"
    },
    {
        name: "Profitability Analysis"
    },
    {
        name: "Interest Analysis"
    },
    {
        name: "Periodic Progress reports"
    },
];

export const SelfDesigning = [
    {
        desc: "OXPAN Software provides facility to design and plan new models in the cooperative by applying various options given for changing various rates and plans."
    }
];

export const MinimizedSteps = [
    {
        desc: "Automation of entry and reporting helps users to get the work done in very few required entries. It helps to save time and efforts as well."
    }
];

export const AutomaticPeriodClosing = [
    {
        name: "Deposit Interest Calcilation"
    },
    {
        name: "Loan Interest Calculation"
    },
    {
        name: "Depreciation"
    },
    {
        name: "Provision for Bad Debts"
    },
    {
        name: "Provision for Income tax and TDS"
    },
    {
        name: "Preparing Profit and Loss Account (Income Statement)"
    },
    {
        name: "Preparing Cash Flow Statement"
    },
    {
        name: "Preparing Balance Sheet"
    },
    {
        name: "Distribute Profit on Funds"
    },
    {
        name: "Allotment of bonus and dividend"
    },
    {
        name: "Perioding provisions from deposits etc."
    },
];

export const MobileBanking = [
    {
        name: "BALANCE QUERY: Deposits, Loan and Interest."
    },
    {
        name: "CHEQUE REQUEST"
    },
    {
        name: " CHEQUE STOP"
    },
    {
        name: "Topup Payments: NTC and Ncell Recharge cards, TV Recharges, Net recharge, Air Ticketing,"
    },
    {
        name: "Bill Payment: School fee, Electricity, Water etc."
    },
    {
        name: "Card-less ATM"
    },
    {
        name: "Collection App"
    },
    {
        name: "Fund Transfer"
    },
    {
        name: "ATM Cards"
    },
    {
        name: "Bank Transfer Etc."
    },
];

export const UpgradedFeatures = [
    {
        name: "Attractive Toolbar with great User Interface"
    },
    {
        name: "More Ease To Use For Banking Transaction"
    },
    {
        name: "Can Store A Copy Of Citizenship, Passport and Other Important Documents"
    },
    {
        name: "Deposit & Loan Collection From Mobile and Tablets"
    },
    {
        name: "Can Use Online By Storing Data To The Cloud"
    },
    {
        name: "Can Perform Multiple Transaction Between Inter Branch And Main Branch"
    },
    {
        name: "Feature Of Mobile Banking & ATM Services"
    },
    {
        name: "Update KYM"
    },
    {
        name: "Include Multiple Reports Of COPOMIS"
    },
    {
        name: "Multiple Reports Of GoAML"
    },
    {
        name: "Special Reports & PEARLS"
    },
    {
        name: "Can Print Share & Deposit Certificate Along With Printing Check Book & Pass Book"
    },
    {
        name: "Daily, Monthly & Annually Closing Can Be Done"
    },
    {
        name: "Can Add Other New Features According To Your Requirements"
    },
    {
        name: "Can Be Easily Upgrade Or Migrate"
    },
]


import React from 'react'
import '../css/serviceslider.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'

import 'swiper/swiper.min.css'
import "swiper/css/pagination";

import DesktopSoft from "../images/services/desktop-software.png"
import WebsiteDev from "../images/services/website-development.png"
import AndroidAppDev from "../images/services/android-app-development.png"
import WebHost from "../images/services/web-hosting.png"
import CloudServ from "../images/services/cloud-service.png"
import SMSserv from "../images/services/sms-service.png"
import MobileBanking from "../images/services/mobile-banking.png"

const ServiceSlider = () => {

    const servicesList = [
        {
            serviceID: "1",
            serviceImg: DesktopSoft,
            serviceName: "Desktop Software",
            description: "Our Team are always eager to develop Desktop Software for your business. There are many desktop software for your business and many customers are using our product proudly. Our team are ready to take challenge to introduce new product and update the new features of existing software.",
            path: "/desktopsoftware",
        },
        {
            serviceID: "2",
            serviceImg: WebsiteDev,
            serviceName: "Website Developments",
            description: "Every company wants to be digitalized. Every company needs a website, and Oxpan is ready to build static and dynamic websites for your business.",
            path: "/websitedevelopment",
        },
        {
            serviceID: "3",
            serviceImg: CloudServ,
            serviceName: "Cloud Services",
            description: "The OXPAN team provides cloud facilities to our cooperative clients. These facilities help move your business forward, and that's why our team offers them to our clients.",
            path: "/cloudservice",
        },
        {
            serviceID: "4",
            serviceImg: SMSserv,
            serviceName: "SMS Services",
            description: "The Oxpan team provides SMS facilities to our clients, which help make their business more useful and reliable. That's why we offer SMS facilities to your business organization.",
            path: "/smsservice",
        },
        {
            serviceID: "5",
            serviceImg: MobileBanking,
            serviceName: "Mobile Banking",
            description: "OXPAN offers mobile banking services, allowing you to access your account on-the-go using your mobile device. With our secure and convenient mobile banking platform, you can perform transactions, check your balance, and manage your finances from anywhere, at any time. Say goodbye to long queues and hello to hassle-free banking with OXPAN's mobile banking.",
            path: "/mobilebanking",
        },
        {
            serviceID: "6",
            serviceImg: AndroidAppDev,
            serviceName: "Android App Development",
            description: "Oxpan is one of the best IT companies in Nepal. We specialize in turning your project ideas into iOS or Android apps, helping you transform your vision into mobile applications.",
            path: "/androidappdev",
        },
        {
            serviceID: "7",
            serviceImg: WebHost,
            serviceName: "Web Hosting",
            description: "We provide the facilities to public your website in internet. Our team are ready to make your website and host the website in internet at suitable price. And also Oxpan team updated your website as per your requirement and provide the best services to our clients.",
            path: "/webhosting",
        },
    ]

    return (

        <>
            <div className="services">
                <h2><span>OUR SERVICES</span></h2><hr />
            </div>
            <div className="service-body">
                <h3>We Offer You Our Best Services</h3>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={20}
                    // slidesPerGroup={1}
                    loop={true}
                    // centeredSlides={true}
                    // fadeEffect={true}
                    grabCursor={true}
                    pagination={{ clickable: true, dynamicBullets: true }}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1
                        },
                        670: {
                            slidesPerView: 2
                        },
                        950: {
                            slidesPerView: 3
                        },
                        1250: {
                            slidesPerView: 4
                        },
                    }}
                    modules={[Pagination, Autoplay]}
                    className="slide-container-service">
                    <div className="slide-content-service">
                        <div className="card-wrapper-service">
                            {servicesList.map((item, id) => {
                                return (
                                    <SwiperSlide className="card shadow">
                                        <div className="card-content-service">
                                            <img src={item.serviceImg} alt="serviceicons" className="img-service" />
                                            <h4 className="name-service">{item.serviceName}</h4>
                                        </div>
                                        <div className="m-5 mb-0 mt-0">
                                            <hr className="hr-service" />
                                        </div>
                                        <div className="desc-content-service">
                                            <div className="card-desc-service">
                                                <p className="desc-service">{item.description}</p>
                                            </div>
                                        </div>
                                        <div className="btn-content-service">
                                            <button className="button-service">
                                                <a href={item.path}>View More</a>
                                            </button>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </div>
                    </div>
                </Swiper>
            </div>
        </>

    )
}

export default ServiceSlider
import React, { useEffect } from 'react'
import DesktopImage from "../../images/services/desktop-software-cover1.png"
import DesktopSoft from "../../images/services/desktop-application-development.png"
import DesktopSoft1 from "../../images/services/desktop-app-dev1.png"
import Aos from 'aos'

import 'aos/dist/aos.css'
import "../../css/service.css"

const DesktopSoftwareDev = () => {

    document.title = "Oxpan Microsys | Desktop Software"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    return (

        <>

            <div className='main-section' data-aos="fade-down">
                <img src={DesktopImage} alt="main" className='main-img' />
                <h2>Desktop Application Development</h2>
                <h3>Desktop Software For Your Business....</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>Desktop Software Development</span></h2><hr />
            </div>

            <div className="intro-content">
                <div className="content-p" data-aos="fade-right">

                    <p>
                        Our team is always eager to develop desktop software for your business. We have many desktop software options for your business, and many customers are proud to use our products. Our team is ready to take on the challenge of introducing new products and updating the features of existing software.
                    </p>

                </div>

                <div className="content-img" data-aos="fade-down">
                    <img src={DesktopSoft} alt="desktopsoftware" />
                </div>

            </div>

            <div className="features" data-aos="fade-right">
                <h2><span>Our Features</span></h2><hr />
            </div>

            <div className='service-content'>

                <div className="service-content-list">

                    <ul className="accordion-service">

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="one" />
                            <label for="one">Timely Deliver</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="two" />
                            <label for="two">User Friendly</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="three" />
                            <label for="three">Easy to Use</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="four" />
                            <label for="four">Flexible Price</label>

                            <div className="content-service1">

                            </div>

                        </li>

                    </ul>

                </div>

                <div className="service-content-img" data-aos="fade-down">
                    <img src={DesktopSoft1} alt="desktopsoftwaredev1" />
                </div>

            </div>

        </>

    )
}

export default DesktopSoftwareDev
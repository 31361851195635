import React, { useEffect } from 'react'
import MainImage from "../../images/MainImg2.jpg"
import CloudService1 from "../../images/services/cloud-computing-service.png"
import CloudService2 from "../../images/services/cloud-service1.gif"
import Aos from 'aos'

import 'aos/dist/aos.css'
import "../../css/service.css"

const CloudService = () => {

    document.title = "Oxpan Microsys | Cloud Service"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    return (

        <>

            <div className='main-section' data-aos="fade-down">
                <img src={MainImage} alt="main" className='main-img' />
                <h2>Cloud Service</h2>
                <h3>Best Cloud Service Facility For Your Business....</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>Cloud Service</span></h2><hr />
            </div>

            <div className="intro-content">
                <div className="content-p" data-aos="fade-right">

                    <p>
                        The OXPAN team provides cloud services to our cooperative clients. This facility helps businesses to move forward, and our team is dedicated to providing the best cloud solutions to our clients.
                    </p>

                </div>

                <div className="content-img" data-aos="fade-down">
                    <img src={CloudService2} alt="cloudservice" />
                </div>

            </div>

            <div className="features" data-aos="fade-right">
                <h2><span>Our Features</span></h2><hr />
            </div>

            <div className='service-content'>

                <div className="service-content-list">

                    <ul className="accordion-service">

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="one" />
                            <label for="one">More Data Secure</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="two" />
                            <label for="two">No Chances Of Data Loss</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="three" />
                            <label for="three">Useful In Multi Branch Purpose</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="four" />
                            <label for="four">Data Access From Anywhere</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="five" />
                            <label for="five">Easy Maintenance</label>

                            <div className="content-service1">

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="six" />
                            <label for="six">Multi-Sharing</label>

                            <div className="content-service1">

                            </div>

                        </li>

                    </ul>

                </div>

                <div className="service-content-img" data-aos="fade-down">
                    <img src={CloudService1} alt="cloudfeature" />
                </div>

            </div>

        </>

    )
}

export default CloudService
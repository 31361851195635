import React, { useEffect } from 'react'
// import AbstractLogo from '../images/abstract-lines.png'
import Aos from 'aos'

import '../../css/footer.css'
import 'aos/dist/aos.css'

const Footer = () => {

    useEffect(() => {
        Aos.init({
            duration: 2000
        })
    }, [])

    const currentDate = new Date().getFullYear()

    return (

        <>
            <div className='container-fluid' style={{ fontFamily: "josefin sans", marginTop: "10px" }}>
                <div className='row'>
                    <div className='col-md-3' style={{ marginRight: "-15px", marginLeft: "20px" }} data-aos="fade-right">
                        <div className='card-body shadow rounded bg-white' style={{ paddingBottom: "10px" }}>
                            <h3 class="text-center pt-3 mt-4 mb-0">Address</h3>
                            <div className="m-5 mb-0 mt-0">
                                <hr className="hr-footer" />
                            </div>
                            <div class="text-center text-secondary address">
                                <h5>Oxpan Microsys Pvt. Ltd.</h5>
                                <address><strong>HEAD OFFICE</strong></address>
                                <address>Sachetan Marga, New Baneshwor, Kathmandu</address>
                                <cite>Phone No: 015905286, 9801906572, 9851135840</cite>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3' style={{ marginRight: "-15px" }} data-aos="fade-up">
                        <div className='card-body shadow rounded bg-white' style={{ paddingBottom: "2px" }}>
                            <h3 className="text-center pt-3 mt-4 mb-0">Branch Office</h3>
                            <div className="m-5 mb-0 mt-0">
                                <hr className="hr-footer" />
                            </div>
                            <div class="text-center text-secondary address">
                                <cite>Surendra Yadav: 9852682940</cite>
                                <address><i className='bi bi-geo-alt-fill' /> Jhapa Branch</address>

                                <cite>Saroj Dahal: 9842531686</cite>
                                <address><i className='bi bi-geo-alt-fill' /> Itahari Branch</address>

                                <cite>Santosh Kafle: 9858024509</cite>
                                <address><i className='bi bi-geo-alt-fill' /> Nepalgunj Branch</address>

                                <cite>Netra Pokharel: 9852681773</cite>
                                <address><i className='bi bi-geo-alt-fill' /> Illam Branch</address>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3' style={{ marginRight: "-15px" }} data-aos="fade-left">
                        <div className="card-body shadow rounded bg-white" style={{ paddingBottom: "10px" }}>
                            <h3 className="text-center pt-3 mt-4 mb-0">Useful Links</h3>
                            <div className="m-5 mb-0 mt-0">
                                <hr className="hr-footer" />
                            </div>
                            <div className="footer-section-3">
                                <a href="/products/goldplus">Cooperator Gold Plus</a><br />
                                <a href="/products/allmart">AllMart</a><br />
                                <a href="/products/bancos">BANCOS</a><br />
                                <a href="/products/coponext">COPONEXT</a><br />
                                <a href="/company/aboutus">About Us</a><br />
                                <a href="/contactus">Contact Us</a><br />
                                <a href="/company/feedback">Give Us Your Feedback</a><br />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3' style={{ marginRight: "-15px" }} data-aos="fade-right">
                        <div className='card-body shadow rounded bg-white'>
                            <h3 className="text-center pt-3 mt-4 mb-0">Our Location</h3>
                            <div className="m-5 mb-0 mt-0">
                                <hr className="hr-footer" />
                            </div>
                            <div className='text-center text-secondary address'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14131.062447901782!2d85.3377149!3d27.6936383!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x15b787518a48a0d!2sOXPAN%20Microsys!5e0!3m2!1sen!2snp!4v1671611176178!5m2!1sen!2snp" width="96%" height="300px" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='location'></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row m-1'>
                    <div className="text-center mt-4 bg-white">
                        <h6 className="mt-3 mb-0">
                            <span>Since 2005</span></h6>
                        <h6 className="text-center">&copy; {currentDate} OXPAN Microsys Pvt. Ltd. All rights reserved.</h6>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Footer
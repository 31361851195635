import React, { useEffect } from 'react'
import MainImage from "../../images/MainImg2.jpg"
import MobileBank1 from "../../images/services/mobile-banking1.gif"
import Aos from 'aos'

import 'aos/dist/aos.css'

const MobileBanking = () => {

    document.title = "Oxpan Microsys | Mobile Banking"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    return (

        <>

            <div className='main-section' data-aos="fade-down">
                <img src={MainImage} alt="main" className='main-img' />
                <h2>Mobile Banking Service</h2>
                <h3>Best Mobile Banking Facility For Your Business....</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>Mobile Banking</span></h2><hr />
            </div>

            <div className="intro-content">
                <div className="content-p" data-aos="fade-right">

                    <p>
                        OXPAN provides mobile banking services to its clients, enabling them to access banking facilities through their smartphones anytime and anywhere. With our mobile banking app, clients can perform various transactions such as fund transfers, bill payments, account statements, and much more. Our app provides a secure and convenient way for customers to manage their finances without visiting the bank physically. OXPAN's mobile banking services are designed to offer a hassle-free banking experience to our clients while keeping their financial data safe and secure.
                    </p>

                </div>

                <div className="content-img" data-aos="fade-down">
                    <img src={MobileBank1} alt="mobile banking" />
                </div>

            </div>

            <div className="features" data-aos="fade-right">
                <h2><span>Our Features</span></h2><hr />
            </div>

        </>

    )
}

export default MobileBanking
import React, { useEffect } from 'react'
import MainImage from "../../images/MainImg2.jpg"
import WebsiteDev1 from "../../images/services/website-dev1.gif"
import WebsiteDev2 from "../../images/services/website-development2.jpg"
import Aos from 'aos'
import {
    WebsiteStaticBasic,
    WebsiteStaticStandard,
    WebsiteStaticPremium,
    WebsiteDynamicBasic,
    WebsiteDynamicStandard,
    WebsiteDynamicPremium
} from './WebsiteDevFeatureList'

import 'aos/dist/aos.css'
import "../../css/service.css"

const WebsiteDevelopment = () => {

    document.title = "Oxpan Microsys | Website Develpment"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    return (

        <>

            <div className='main-section' data-aos="fade-down">
                <img src={MainImage} alt="main" className='main-img' />
                <h2>Website Development</h2>
                <h3>Get Attractive Website For Your Organization At Affordable Price....</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>Website Development</span></h2><hr />
            </div>

            <div className="intro-content">
                <div className="content-p" data-aos="fade-right">

                    <p>
                        Nowadays, every company wants to be digitalized. Every company needs a website, and Oxpan is ready to build a static and dynamic website for your business. There are a range of website standards which are shown below in features.
                    </p>

                </div>

                <div className="content-img" data-aos="fade-down">
                    <img src={WebsiteDev1} alt="website dev 1" />
                </div>

            </div>

            <div className="features" data-aos="fade-right">
                <h2><span>Our Features</span></h2><hr />
            </div>

            <div className='service-content'>

                <div className="service-content-list">

                    <ul className="accordion-service">

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="one" />
                            <label for="one">Static Basic</label>

                            <div className="content-service1">

                                {WebsiteStaticBasic.map((item1) => {
                                    return (
                                        <li>{item1.list}</li>
                                    )
                                })}

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="two" />
                            <label for="two">Static Standard</label>

                            <div className="content-service1">

                                {WebsiteStaticStandard.map((item2) => {
                                    return (
                                        <li>{item2.list}</li>
                                    )
                                })}

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="three" />
                            <label for="three">Static Premium</label>

                            <div className="content-service1">

                                {WebsiteStaticPremium.map((item3) => {
                                    return (
                                        <li>{item3.list}</li>
                                    )
                                })}

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="four" />
                            <label for="four">Dynamic Basic</label>

                            <div className="content-service1">

                                {WebsiteDynamicBasic.map((item4) => {
                                    return (
                                        <li>{item4.list}</li>
                                    )
                                })}

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="five" />
                            <label for="five">Dynamic Standard</label>

                            <div className="content-service1">

                                {WebsiteDynamicStandard.map((item5) => {
                                    return (
                                        <li>{item5.list}</li>
                                    )
                                })}

                            </div>

                        </li>

                        <li data-aos="fade-right">

                            <input type="radio" name="accordion-service" id="six" />
                            <label for="six">Dynamic Premium</label>

                            <div className="content-service1">

                                {WebsiteDynamicPremium.map((item6) => {
                                    return (
                                        <li>{item6.list}</li>
                                    )
                                })}

                            </div>

                        </li>

                    </ul>

                </div>

                <div className="service-content-img" data-aos="fade-down">
                    <img src={WebsiteDev2} alt="website dev 2" />
                </div>

            </div>

        </>

    )
}

export default WebsiteDevelopment
export const WebsiteStaticBasic = [
    {
        list: "Logo And Poster Design"
    },
    {
        list: "Upto 5 Pages"
    },
];

export const WebsiteStaticStandard = [
    {
        list: "Logo And Poster Design"
    },
    {
        list: "Upto 10 Pages"
    },
    {
        list: "Responsive User Interface"
    }
];

export const WebsiteStaticPremium = [
    {
        list: "Logo And Poster Design"
    },
    {
        list: "Upto 15 Pages"
    },
    {
        list: "Responsive User Interface"
    },
    {
        list: "Search Engine Optimization"
    }
];

export const WebsiteDynamicBasic = [
    {
        list: "Responsive User Interface"
    },
    {
        list: "Search Engine Optimization"
    },
    {
        list: "Database Access"
    }
];

export const WebsiteDynamicStandard = [
    {
        list: "Responsive User Interface"
    },
    {
        list: "Search Engine Optimization"
    },
    {
        list: "Database Access"
    },
    {
        list: "Registration And Login"
    },
];

export const WebsiteDynamicPremium = [
    {
        list: "Responsive User Interface"
    },
    {
        list: "Search Engine Optimization"
    },
    {
        list: "Database Access"
    },
    {
        list: "Registration And Login"
    },
    {
        list: "Logo And Poster Design"
    },
    {
        list: 'E-Commerce'
    }
];

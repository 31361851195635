import React, { useEffect } from 'react'
import MainImage from "../../images/MainImg2.jpg"
import Feedback1 from "../../images/feedback.webp"
import Aos from 'aos'
import {
    Form,
    Col,
    Row,
    Button
} from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import swal from 'sweetalert'
import emailjs from "@emailjs/browser"

import 'aos/dist/aos.css'
import "../../css/feedback.css"

const Feedback = () => {

    document.title = "Oxpan Microsys | Feedback"

    useEffect(() => {
        Aos.init({
            duration: 1500,
            once: false,
            mirror: false,
            easing: 'ease-in-out-sine'
        })
    }, [])

    const { REACT_APP_PUBLIC_KEY, REACT_APP_TEMPLATE_ID, REACT_APP_SERVICE_ID } = process.env

    const phoneRegExp = /^\d{10}$/; // Regular expression to match 10-digit phone number

    const validateFeedbackSchema = Yup.object().shape({
        name: Yup.string()
            .required("* Name is Required !!")
            .min(5, "* Must have at least 5 Characters !!")
            .max(25, "* Must be less than 25 Characters !!"),
        coopname: Yup.string()
            .required("* Cooperative Name is Required !!")
            .min(5, "* Must have at least 5 Characters !!")
            .max(60, "* Must be less than 60 Characters !!"),
        contactno: Yup.string()
            .matches(phoneRegExp, "* Invalid Number !!")
            .required("* Number Required !!"),
        address: Yup.string()
            .required("* Address is Required !!")
            .min(5, "* Must have at least 5 Characters !!")
            .max(30, "* Must be less than 30 Characters !!"),
        message: Yup.string()
            .required("* Message is Required !!")
            .min(5, "* Must have at least 5 Characters !!")
            .max(100, "* Must be less than 100 Characters !!"),
        email: Yup.string().email()
            .required("* Email is Required!!"),
        subject: Yup.string()
            .required("* Subject is Required !!")
            .min(5, "* Must have at least 5 Characters !!")
            .max(50, "* Must be less than 50 Characters !!"),
    })

    return (

        <>

            <div className='main-section' data-aos="fade-down">
                <img src={MainImage} alt="main" className='main-img' />
                <h2>Your Feedback</h2>
                <h3>Lets Share Your Thoughts and Complains....</h3>
            </div>

            <div className="introduction" data-aos="fade-right">
                <h2><span>Feedback</span></h2><hr />
            </div>

            <div className="feedback-content">

                <div className="feedback-form" data-aos="fade-right">

                    <Formik
                        initialValues={{ name: "", coopname: "", contactno: "", address: "", email: "", subject: "", message: "" }}
                        validationSchema={validateFeedbackSchema}
                        onSubmit={
                            (values, { setSubmitting, resetForm }) => {
                                setSubmitting(true)
                                emailjs.send(`${REACT_APP_SERVICE_ID}`, `${REACT_APP_TEMPLATE_ID}`, values, `${REACT_APP_PUBLIC_KEY}`)
                                    .then(resetForm())
                                    .then(setSubmitting(false))
                                    .then(swal({
                                        title: "Feedback Sent !",
                                        text: "Thank You, We'll Contact You Shortly !",
                                        icon: "success",
                                    }))
                            }
                        }
                    >

                        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (

                            <Form onSubmit={handleSubmit}>

                                <Form.Group as={Row} className="mb-3" controlId="name">

                                    <Form.Label column sm="2">Full Name :</Form.Label>

                                    <Col sm="8">
                                        <Form.Control type="text" name="name" placeholder="Enter Your Full Name" onChange={handleChange} value={values.name} style={{ borderColor: touched.name && errors.name ? "red" : null }} />
                                        {touched.name && errors.name ? (
                                            <Col className="error-message">{errors.name}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="coopname">

                                    <Form.Label column sm="3">Cooperative Name:</Form.Label>

                                    <Col sm="8">
                                        <Form.Control type="text" name="coopname" placeholder="Enter Cooperative Name" onChange={handleChange} value={values.coopname} style={{ borderColor: touched.coopname && errors.coopname ? "red" : null }} />
                                        {touched.coopname && errors.coopname ? (
                                            <Col className="error-message">{errors.coopname}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="contactno">

                                    <Form.Label column sm="3">Mobile NO:</Form.Label>

                                    <Col sm="8">
                                        <Form.Control type="text" name="contactno" placeholder="Enter Mobile Number" onChange={handleChange} value={values.contactno} style={{ borderColor: touched.contactno && errors.contactno ? "red" : null }} />
                                        {touched.contactno && errors.contactno ? (
                                            <Col className="error-message">{errors.contactno}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="address">

                                    <Form.Label column sm="3">Address:</Form.Label>

                                    <Col sm="8">
                                        <Form.Control type="text" name="address" placeholder="Enter Address" onChange={handleChange} value={values.address} style={{ borderColor: touched.address && errors.address ? "red" : null }} />
                                        {touched.address && errors.address ? (
                                            <Col className="error-message">{errors.address}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="email">

                                    <Form.Label column sm="3">Email :</Form.Label>

                                    <Col sm="8">
                                        <Form.Control type="email" name="email" placeholder="Enter Your Email" onChange={handleChange} value={values.email} style={{ borderColor: touched.email && errors.email ? "red" : null }} />
                                        {touched.email && errors.email ? (
                                            <Col className="error-message">{errors.email}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="subject">

                                    <Form.Label column sm="3">Subject :</Form.Label>

                                    <Col sm="8">
                                        <Form.Control type="text" name="subject" placeholder="Enter Your Subject" onChange={handleChange} value={values.subject} style={{ borderColor: touched.subject && errors.subject ? "red" : null }} />
                                        {touched.subject && errors.subject ? (
                                            <Col className="error-message">{errors.subject}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Form.Group className="mb-3" controlId="message">

                                    <Form.Label column sm="3">Message :</Form.Label>

                                    <Col>
                                        <Form.Control as="textarea" name="message" rows={5} placeholder="Enter Your Message Here....." onChange={handleChange} value={values.message} style={{ borderColor: touched.message && errors.message ? "red" : null }} />
                                        {touched.message && errors.message ? (
                                            <Col className="error-message">{errors.message}</Col>
                                        ) : null}
                                    </Col>

                                </Form.Group>

                                <Button variant="primary" type="submit" disabled={isSubmitting}>SEND FEEDBACK <i className="bi bi-send" /></Button>
                                {/* <Button variant="danger" size="sm" onClick={handleCloseContact} ><i className="bi bi-trash3" /></Button> */}

                            </Form>
                        )}

                    </Formik>

                </div>

                <div className="feedback-img" data-aos="fade-down">
                    <img src={Feedback1} alt="feedback" />
                </div>

            </div>

        </>

    )
}

export default Feedback